<template>
    <div class="bg-white shadow-lg rounded-lg mr-3 ml-3 pb-5 mt-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">推廣大圖資訊</div>
                <div class="inputs">
                    <div class="input">
                        <div class="profile" @click="goProfile">
                            <img class="profile-img" :src="inputs.cover" alt="" />
                            <div class="name">
                                {{ inputs.name }}
                            </div>
                        </div>
                    </div>
                    <div class="input">
                        <div class="label">啟用狀態</div>
                        <el-select v-model="inputs.activated" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in openOption" :key="idx" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">使用期間</div>
                        <el-select v-model="inputs.isDuring" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in duringOption" :key="idx" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">期間範圍</div>
                        <el-date-picker
                            v-model="inputDate"
                            :disabled="inputs.isDuring == 0"
                            style="width: 100%"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="bottom-content">
                <div class="title text-black-blod-18">推廣大圖圖片</div>
                <div class="upload-area">
                    <div class="left">
                        <div class="label">電腦版（上傳尺寸限制：{{ imageLimit.desktop.width }}*{{ imageLimit.desktop.height }}）</div>
                        <el-upload
                            action="#"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="changeFiledDesktop"
                            accept=".jpg, .jpeg, .png"
                            list-type="picture"
                            :file-list="fileList1">
                            <button v-if="!imgObj1.url" class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px]">上傳圖片</button>
                            <div slot="file"></div>
                        </el-upload>
                        <div v-if="imgObj1.url" class="display-img">
                            <div class="error" @click="handleRemove(1)">
                                <i class="fas fa-times"></i>
                            </div>
                            <img :src="imgObj1.url" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="black-btn-outline-lg text-md mr-5" @click="goBack">返回列表</button>
            <button v-permission="['create']" class="orange-btn-800-lg text-md" @click="create">儲存變更</button>
        </div>
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// base64 轉 Blob
import { dataURLtoBlob } from "@/service/imageTransfer.js";
const defaultImg = {
    url: "",
    file: null,
    base64: "",
};
export default {
    name: "EditComer",
    components: {
        LoadingComponent,
    },
    computed: {
        // 啟用選項
        openOption() {
            return [
                { label: "啟用", value: 1 },
                { label: "停用", value: 0 },
            ];
        },
        // 使用期間選項
        duringOption() {
            return [
                { label: "不限使用期間", value: 0 },
                { label: "限定使用期間", value: 1 },
            ];
        },
    },
    data() {
        return {
            inputs: {
                id: 0,
                cover: "",
                name: "",
                start: null,
                end: null,
                position: 1,
                activated: 1,
                isDuring: 0,
            },
            inputDate: [],
            loading: true,
            imgObj1: { ...defaultImg },
            imgObj2: { ...defaultImg },
            fileList1: [],
            fileList2: [],
            imageLimit: {
                desktop: {
                    width: 1024,
                    height: 768,
                },
            },
        };
    },
    methods: {
        // 移除圖片
        handleRemove(num) {
            if (num === 1) {
                this.imgObj1 = { ...defaultImg };
                this.fileList1 = [];
            } else {
                this.imgObj2 = { ...defaultImg };
                this.fileList2 = [];
            }
        },
        // 更新圖片
        changeFiledDesktop(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList1 = [];
                alert("檔案大小超出5mb");
                return;
            }
            this.fileList1 = fileList.slice(-3);
            this.imgObj1.url = file.url;
            this.imgObj1.file = file.raw;

            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                this.imgObj1.base64 = reader.result;
                // 確認 圖片 size
                this.checkImageLimit(this.imgObj1.base64, this.imageLimit.desktop);
            };
        },
        // 確認圖片寬高
        checkImageLimit(base64, limit) {
            const img = new Image();
            img.src = base64;
            img.onload = () => {
                if (img.height !== limit.height || img.width !== limit.width) {
                    this.fileList1 = [];
                    this.imgObj1 = { ...defaultImg };
                    alert("圖片不符合寬高限制");
                }
            };
        },
        // 新增 API
        create() {
            const { id, isDuring, end, start, position, activated } = this.inputs;
            if (isDuring) {
                if (!start || !end) {
                    alert("使用期間未填寫完整");
                    return;
                }
            }
            if (!this.imgObj1.base64) {
                alert("圖片未正確填入");
                return;
            }
            const result = new FormData();

            if (this.imgObj1.base64) {
                const blob = dataURLtoBlob(this.imgObj1.base64);
                result.append("image", blob);
            }

            result.append("user_id", id);
            result.append("duration", isDuring);
            if (isDuring) {
                result.append("start", start);
                result.append("end", end);
            }
            result.append("category", position);
            result.append("activated", activated);
            this.loading = true;
            this.$api
                .CreatePromo(result)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    this.goBack();
                })
                .catch((err) => {
                    console.log(err);
                    const errorMessage = err.response.data.error.message === "Category not found" ? "此服務商未開啟吃喝玩樂" : "新增失敗";
                    this.$message({
                        type: "error",
                        message: errorMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 返回列表
        goBack() {
            this.$router.push({ path: "/homepage_setting/promote_img" });
        },
        // 前往服務商頁面
        goProfile() {
            window.open(`/#/user_list/provider_update/${this.inputs.id}`);
        },
        // 取得此使用資訊
        getData(data) {
            const { name, id, cover } = data;
            this.inputs = { ...this.inputs, cover, name, id };
            this.loading = false;
        },
    },
    watch: {
        // 監聽 element-ui 日期輸入匡，將值設定於輸入匡變數中
        inputDate(val) {
            if (val === null || val.length === 0) {
                this.inputs.start = "";
                this.inputs.end = "";
                return;
            }
            this.inputs.start = val[0];
            this.inputs.end = val[1];
        },
    },
    async mounted() {
        const { status, data } = await this.$api
            .UserSearchByPhone(this.$route.params.phone)
            .then((res) => {
                return { status: res.status, data: res.data };
            })
            .catch(() => {
                return { status: 400, data: { message: "err" } };
            })
            .finally(() => {
                this.loading = false;
            });

        if (status === 200) {
            this.getData(data);
        } else {
            alert("請確認手機號碼");
            this.goBack();
        }
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            border-bottom: 1px solid #757575;
            .input {
                width: 48%;
                padding: 10px 0px;
                .profile {
                    display: flex;
                    align-items: center;
                    .profile-img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                    .name {
                        color: #ff5733;
                        text-decoration: underline;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                max-width: 50%;
                flex: 1;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
